import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Importiere die CSS-Datei

import start_icon from './img/start.png';
import stop_icon from './img/stop.png';
import delete_icon from './img/trash.png';

import minecraftImage from './img/minecraft-icon.jpg';
import valheimImage from './img/valheim-icon.png';
import rustImage from './img/rust-icon.png';
import palworldImage from './img/palworld-icon.png';


const getRandomPort = () => {
  return Math.floor(Math.random() * 64512) + 1024; // Nur erlaubte Ports von 1024 bis 65535
};

const UserServerManager = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [runningServers, setRunningServers] = useState(0);
  const [serverName, setServerName] = useState('');
  const [port, setPort] = useState('');
  const [version, setVersion] = useState('latest');
  const [type, setType] = useState('vanilla');
  const [ram, setRam] = useState('1G');
  const [eula, setEula] = useState(false);
  const [rconPort, setRconPort] = useState('');
  const [rconPassword, setRconPassword] = useState('');
  const [worldName, setWorldName] = useState('');
  const [password, setPassword] = useState('');
  const [publicServer, setPublicServer] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [autoBackup, setAutoBackup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [userGames, setUserGames] = useState([]); // Mehrere Spiele
  const [selectedGame, setSelectedGame] = useState('');
  const [maxServers, setMaxServers] = useState(1); // Standardmäßig 1
  const [serverVersions, setServerVersions] = useState({}); // State to store the version for each server


useEffect(() => {
    fetchUserDetails();
    fetchServers();
    setPort(getRandomPort());
    setRconPort(getRandomPort());
  }, []);

useEffect(() => {
    console.log('User games updated:', userGames); // Debugging-Ausgabe
  }, [userGames]);


const fetchUserDetails = async () => {
  try {
    const response = await axios.get(`https://jelly-hosting.com/user-details`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    console.log('User details:', response.data); // Debugging-Ausgabe
    const bookedServers = JSON.parse(response.data.bookedServers); // JSON.parse zum Analysieren der Zeichenkette
    console.log('Booked Servers:', bookedServers); // Debugging-Ausgabe
    setUserGames(Array.isArray(bookedServers) ? bookedServers : []);
    setMaxServers(response.data.maxServers);
  } catch (error) {
    console.error('Error fetching user details:', error);
  }
};

const fetchServers = () => {
  setLoading(true);
  axios.get(`https://jelly-hosting.com/servers`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      setServers(response.data.servers);
      setRunningServers(response.data.runningServers);
      // Initialize server versions state
      const initialVersions = {};
      response.data.servers.forEach(server => {
        initialVersions[server.name] = server.version || 'latest';
      });
      setServerVersions(initialVersions);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching servers:', error);
      alert('Error fetching servers');
      setLoading(false);
    });
};

const validateFields = () => {
  if (!serverName) {
    setFeedback('Server Name is required');
    return false;
  }
  if (!port) {
    setFeedback('Port is required');
    return false;
  }
  if (selectedGame !== 'valheim' && !rconPort && selectedGame !== 'csgo') {
    setFeedback('RCON Port is required');
    return false;
  }
  if (selectedGame === 'valheim') {
    if (publicServer && !password) {
      setFeedback('Cannot start a public Valheim server without a password.');
      return false;
    }
    if (password.length < 5) {
      setFeedback('Password for Valheim server must be at least 5 characters long.');
      return false;
    }
    if (type === 'vanilla' && publicServer) {
      setFeedback('Cannot start a public Vanilla Valheim server.');
      return false;
    }
  }
  if (servers.some(server => server.name === serverName)) {
    setFeedback('Server name already exists');
    return false;
  }
  if (servers.some(server => server.port === parseInt(port) || server.rcon_port === parseInt(port) || server.port === parseInt(rconPort) || server.rcon_port === parseInt(rconPort))) {
    setFeedback('Port or RCON port is already in use');
    return false;
  }
  return true;
};

const addServer = () => {
  if (runningServers >= maxServers) {
    setFeedback(`Maximum number of running servers reached (${maxServers}). Please stop or delete a server before adding a new one.`);
    return;
  }

  if (!validateFields()) {
    return;
  }

  setActionLoading(true);
  setFeedback('Adding server...');

  axios.post(`https://jelly-hosting.com/create-server`, {
    serverName, port, version, type, game: selectedGame, ram, eula: eula ? 'TRUE' : 'FALSE', rcon_port: rconPort, rcon_password: rconPassword, world_name: worldName, password, public_server: publicServer, auto_update: autoUpdate, auto_backup: autoBackup
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      setFeedback(response.data);
      fetchServers();
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        setFeedback('Server name or port is already in use.');
      } else {
        setFeedback('Error starting server');
      }
      console.error(error);
    })
    .finally(() => {
      setActionLoading(false);
    });
};

const startServer = (serverName, newVersion) => {
  setActionLoading(true);
  setFeedback('Starting server...');

  axios.post(`https://jelly-hosting.com/start-server`, { serverName, newVersion }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      setFeedback(response.data);
      fetchServers();
    })
    .catch(error => {
      console.error(error);
      setFeedback('Error starting server');
    })
    .finally(() => {
      setActionLoading(false);
    });
};

const stopServer = (serverName) => {
  const userConfirmed = window.confirm(`Soll "${serverName}" wirklich angehalten werden?`);

  if (!userConfirmed) {
    return; // If the user clicks "Cancel", we stop the execution of the function.
  }
  setActionLoading(true);
  setFeedback('Stopping server...');

  axios.post(`https://jelly-hosting.com/stop-server`, { serverName }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      setFeedback(response.data);
      fetchServers();
    })
    .catch(error => {
      console.error(error);
      setFeedback('Error stopping server');
    })
    .finally(() => {
      setActionLoading(false);
    });
};

const deleteServer = (serverName) => {
  const userConfirmed = window.confirm(`🚨ACHTUNG: "${serverName}" wird gleich FÜR IMMER gelöscht🚨`);

  if (!userConfirmed) {
    return; // If the user clicks "Cancel", we stop the execution of the function.
  }

  setActionLoading(true);
  setFeedback('Deleting server...');

  axios.delete(`https://jelly-hosting.com/delete-server`, {
    headers: { Authorization: `Bearer ${token}` },
    data: { serverName }
  })
    .then(response => {
      setFeedback(response.data);
      fetchServers();
    })
    .catch(error => {
      console.error(error);
      setFeedback('Error deleting server');
    })
    .finally(() => {
      setActionLoading(false);
    });
};

const renderMinecraftOptions = () => (
  <>
    <select value={type} onChange={e => setType(e.target.value)}>
      <option value="vanilla">Vanilla</option>
      <option value="bukkit">Bukkit</option>
      <option value="forge">Forge</option>
    </select>
    <select value={version} onChange={e => setVersion(e.target.value)}>
      <option value="latest">Latest</option>
      <option value="1.12.2">1.12.2</option>
      <option value="1.16.5">1.16.5</option>
      <option value="1.17.1">1.17.1</option>
      <option value="1.18.2">1.18.2</option>
      <option value="1.19.3">1.19.3</option>
      <option value="1.21">1.21</option>
    </select>
    <select value={ram} onChange={e => setRam(e.target.value)}>
      <option value="512M">512 MB</option>
      <option value="1G">1 GB</option>
      <option value="2G">2 GB</option>
      <option value="4G">4 GB</option>
    </select>
    <label>
      <input
        type="checkbox"
        checked={eula}
        onChange={e => setEula(e.target.checked)}
      />
      Accept EULA
    </label>
    <input
      type="text"
      placeholder="RCON Port"
      value={rconPort}
      onChange={e => setRconPort(e.target.value)}
    />
    <input
      type="text"
      placeholder="RCON Password"
      value={rconPassword}
      onChange={e => setRconPassword(e.target.value)}
    />
  </>
);

const renderValheimOptions = () => (
  <>
    <input
      type="text"
      placeholder="World Name"
      value={worldName}
      onChange={e => setWorldName(e.target.value)}
    />
    <input
      type="text"
      placeholder="Server Password"
      value={password}
      onChange={e => setPassword(e.target.value)}
    />
    <label>
      <input
        type="checkbox"
        checked={publicServer}
        onChange={e => setPublicServer(e.target.checked)}
      />
      Public Server
    </label>
    <label>
      <input
        type="checkbox"
        checked={autoUpdate}
        onChange={e => setAutoUpdate(e.target.checked)}
      />
      Auto Update
    </label>
    <label>
      <input
        type="checkbox"
        checked={autoBackup}
        onChange={e => setAutoBackup(e.target.checked)}
      />
      Auto Backup
    </label>
  </>
);

const renderGameOptions = () => {
  switch (selectedGame) {
    case '':
      return <p>Please select a Game</p>;
    case 'minecraft':
      return renderMinecraftOptions();
    case 'valheim':
      return renderValheimOptions();
    case 'csgo':
      return <p>CS:GO does not require additional options.</p>;
    case 'ark':
      return (
        <>
          <input
            type="text"
            placeholder="RCON Port (optional)"
            value={rconPort}
            onChange={e => setRconPort(e.target.value)}
          />
          <input
            type="text"
            placeholder="RCON Password (optional)"
            value={rconPassword}
            onChange={e => setRconPassword(e.target.value)}
          />
        </>
      );
    case 'rust':
      return (
        <>
          <input
            type="text"
            placeholder="RCON Port"
            value={rconPort}
            onChange={e => setRconPort(e.target.value)}
          />
          <input
            type="text"
            placeholder="RCON Password"
            value={rconPassword}
            onChange={e => setRconPassword(e.target.value)}
          />
          <select value={ram} onChange={e => setRam(e.target.value)}>
            <option value="512M">512 MB</option>
            <option value="1G">1 GB</option>
            <option value="2G">2 GB</option>
            <option value="4G">4 GB</option>
            <option value="6G">6 GB</option>
          </select>
        </>
      );
    default:
      return null;
  }
};

const getGameImage = (game) => {
  switch (game) {
    case 'minecraft':
      return minecraftImage; // Nur Minecraft für jetzt
    case 'rust':
      return rustImage;
    case "valheim":
      return valheimImage;
    case "palworld":
      return palworldImage;
    // Füge hier weitere Fälle für andere Spiele hinzu
    default:
      return null; // Kein Bild für unbekannte Spiele
  }
};

const renderServerInfo = (server) => {
  const serverInfo = [
    { label: 'Version', value: server.version },
    { label: 'Type', value: server.type },
    { label: 'RAM', value: server.ram },
    { label: 'RCON Port', value: server.rcon_port },
    { label: 'RCON Password', value: server.rcon_password },
    { label: 'World', value: server.world_name },
    { label: 'Password', value: server.password },
    { label: 'Public', value: server.public_server ? 'Yes' : 'No' },
    { label: 'Auto Update', value: server.auto_update ? 'Enabled' : 'Disabled' },
    { label: 'Auto Backup', value: server.auto_backup ? 'Enabled' : 'Disabled' },
  ].filter(info => info.value); // Filtert nur Einträge, die einen Wert haben

  return (
    <div className="server-info-container">
      {getGameImage(server.game) && (
        <img
          src={getGameImage(server.game)}
          alt={server.game}
          className="server-info-image"
        />
      )}

      <dl class = "server-info-text">
        {serverInfo.map((info, index) => (
          <div key={index}>
            <label>{info.label}:{info.value}</label>
          </div>
        ))}
      </dl>
    </div>
  );
};


// In deiner React-Komponente
return (
  <div className="container">
    <label>Running Servers: {runningServers} / {maxServers}</label>

    <div className="">
      <select value={selectedGame} onChange={e => setSelectedGame(e.target.value)}>
        <option value="">Select Game</option>
        {userGames.length > 0 ? (
          userGames.map(game => (
            <option key={game} value={game}>{game}</option>
          ))
        ) : (
          <option>No games available</option>
        )}
      </select>
    </div>
    <input
      type="text"
      placeholder="Server Name"
      value={serverName}
      onChange={e => setServerName(e.target.value)}
    />
    <input
      type="text"
      placeholder="Port"
      value={port}
      onChange={e => setPort(e.target.value)}
    />
    <div className="container">
      {renderGameOptions()}
    </div>
    <div className="buttons">
      <button class = "round_button" onClick={addServer} disabled={runningServers >= maxServers}>
        Add Server
      </button>
    </div>
    
    {loading ? (
      <p>Loading...</p>
    ) : (
      <ul>
        {servers.map((server, index) => (
          <li key={index}>
            <label>Name: {server.name}</label>
            <label>Status:{server.status}</label>
            <label>{server.serverIp}:{server.port}</label>
            {renderServerInfo(server)}
            <div className="game_server_action_btn_container">
              <button
                className="game_server_action_btn"
                onClick={() => startServer(server.name, serverVersions[server.name] || server.version || 'latest')}
                disabled={server.status === 'running' || actionLoading}
              >
                <img src={start_icon} alt="Start" />
              </button>

              <button
                className="game_server_action_btn"
                onClick={() => stopServer(server.name)}
                disabled={server.status === 'stopped' || actionLoading}
              >
                <img src={stop_icon} alt="Stop" />
              </button>

              <button
                className="game_server_action_btn"
                onClick={() => deleteServer(server.name)}
                disabled={actionLoading}
              >
                <img src={delete_icon} alt="Delete" />
              </button>
            </div>
          </li>
        ))}
      </ul>

    )}
    {actionLoading && <p>Loading...</p>}
    <p>{feedback}</p>
  </div>
);



};

export default UserServerManager;
