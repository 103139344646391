import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServerManager from './ServerManager';
import AdminConsole from './AdminConsole';
import ServerStatus from './ServerStatus';
import DockerLogs from './DockerLogs';
import Login from './Login';
import StatsChart from './StatsChart';
import Navbar from './Navbar';
import Register from './Register';
import UserServerManager from './UserServerManager';
import ChangePassword from './ChangePassword';
import UserAdmin from './UserAdmin'; // Importiere die UserAdmin Komponente
import ServerModManager from './ServerModManager'; // Importiere die neue Komponente
import './styles.css';

const App = () => {
  const [token, setToken] = useState('');
  const [role, setRole] = useState('');
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedModule, setSelectedModule] = useState('ServerManager');

  useEffect(() => {
    const fetchUserRoleAndPayment = async () => {
      if (token) {
        try {
          const response = await axios.get('https://jelly-hosting.com/user-details', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setRole(response.data.role);
          setHasPaid(response.data.hasPaid);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };

    fetchUserRoleAndPayment();
  }, [token]);

  if (!token) {
    return <Login setToken={setToken} setRole={setRole} setHasPaid={setHasPaid} />;
  }

  const renderModule = () => {
    if (role === 'admin') {
      switch (selectedModule) {
        case 'ServerManager':
          return <ServerManager token={token} />;
        case 'AdminConsole':
          return <AdminConsole token={token} />;
        case 'DockerLogs':
          return <DockerLogs token={token} />;
        case 'StatsChart':
          return <StatsChart token={token} />;
        case 'ServerStatus':
          return <ServerStatus token={token} />;
        case 'Register':
          return <Register />;
        case 'UserServerManager':
          return <UserServerManager token={token} />;
        case 'UserAdmin': // Hinzufügen des neuen Falls für UserAdmin
          return <UserAdmin token={token} />;
        case 'ServerModManager': // Hinzufügen des neuen Falls für ServerModManager
          return <ServerModManager token={token} />;
        default:
          return <ServerManager token={token} />;
      }
    } else if (hasPaid) {
    return (
      <div>
        <UserServerManager token={token} />
        <ChangePassword token={token} />
      </div>
    );
  } else {
      return <div>Access denied. You must be an admin or have paid to access this module.</div>;
    }
  };

  return (
    <div className="app-container">
      <Navbar onSelect={setSelectedModule} selected={selectedModule} token={token} role={role} setToken={setToken} />
      <div className="content">
        {renderModule()}
      </div>
    </div>
  );
};

export default App;
