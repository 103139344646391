import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';

const ChangePassword = ({ token, setToken }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validierung: Überprüfe, ob das neue Passwort und das Bestätigungs-Passwort übereinstimmen
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirmation password do not match.');
      return;
    }

    try {
      const response = await axios.put('https://jelly-hosting.com/change-password', {
        oldPassword,
        newPassword
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        // Erfolgreiche Passwortänderung: Benutzer ausloggen und zur Login-Seite leiten
        alert('Password updated successfully. Please log in again.');
        setToken(null); // Token zurücksetzen
        window.location.href = '/login'; // Redirect to login page
      } else {
        // Falls die Antwort nicht 200 OK ist, zeige eine Fehlermeldung
        setErrorMessage('Unexpected response from server. Please try again.');
      }
    } catch (error) {
      // Falls ein Fehler auftritt, gib die entsprechende Fehlermeldung aus
      if (error.response && error.response.status === 400) {
        setErrorMessage('Old password is incorrect.');
      } else {
        setErrorMessage('Passwort wurde geändert. Auto Logout + Token kaputt machen geht noch ned');
      }
    }
  };

  return (
    <div className="container">
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div>
          <label>Old Password:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Confirm New Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
