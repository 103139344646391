import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';

const DockerLogs = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [logs, setLogs] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchServers();
  }, []);

  const fetchServers = () => {
    axios.get('https://jelly-hosting.com/servers', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setServers(response.data.servers);
        if (response.data.servers.length > 0) {
          setSelectedServer(response.data.servers[0].name);
        }
      })
      .catch(error => {
        console.error(error);
        alert('Error fetching servers');
      });
  };

  const fetchLogs = () => {
    if (!selectedServer) {
      alert('Please select a server');
      return;
    }

    setLoading(true);
    axios.get(`https://jelly-hosting.com/docker-logs/${selectedServer}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setLogs(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        alert('Error fetching logs');
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <h2>Docker Logs</h2>
      <select value={selectedServer} onChange={e => setSelectedServer(e.target.value)}>
        {servers.map(server => (
          <option key={server.name} value={server.name}>
            {server.name}
          </option>
        ))}
      </select>
      <button onClick={fetchLogs}>Fetch Logs</button>
      {loading ? <p>Loading...</p> : <pre>{logs}</pre>}
    </div>
  );
};

export default DockerLogs;
