import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Importiere die CSS-Datei

const getRandomPort = () => Math.floor(Math.random() * (65535 - 1024 + 1)) + 1024;

const ServerManager = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [runningServers, setRunningServers] = useState(0);
  const [usedRamServer1, setUsedRamServer1] = useState(0);
  const [usedRamServer2, setUsedRamServer2] = useState(0);
  const [serverName, setServerName] = useState('');
  const [port, setPort] = useState(getRandomPort());
  const [version, setVersion] = useState('latest');
  const [game, setGame] = useState('');
  const [type, setType] = useState('vanilla');
  const [ram, setRam] = useState('1G');
  const [eula, setEula] = useState(false);
  const [rconPort, setRconPort] = useState(getRandomPort());
  const [rconPassword, setRconPassword] = useState('');
  const [worldName, setWorldName] = useState('');
  const [password, setPassword] = useState('');
  const [publicServer, setPublicServer] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [autoBackup, setAutoBackup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [role, setRole] = useState('user'); // Standardmäßig 'user', kann auf 'admin' gesetzt werden
  const [serverVersions, setServerVersions] = useState({}); // State to store the version for each server

  useEffect(() => {
    fetchUserDetails();
    fetchServers();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('https://jelly-hosting.com/user-details', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchServers = () => {
    setLoading(true);
    axios.get('https://jelly-hosting.com/servers', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        const servers = response.data.servers;
        setServers(servers);
        setRunningServers(response.data.runningServers);

        // Berechnung der genutzten RAM pro Server
        const server1Ip = '93.90.188.29'; // Ersetze 'Server1_IP' durch die tatsächliche IP-Adresse von Server 1
        const server2Ip = '78.46.46.124'; // Ersetze 'Server2_IP' durch die tatsächliche IP-Adresse von Server 2

        const server1Ram = servers
          .filter(server => server.serverIp === server1Ip)
          .reduce((total, server) => total + parseInt(server.ram.replace('G', '')), 0);

        const server2Ram = servers
          .filter(server => server.serverIp === server2Ip)
          .reduce((total, server) => total + parseInt(server.ram.replace('G', '')), 0);

        setUsedRamServer1(server1Ram);
        setUsedRamServer2(server2Ram);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        alert('Error fetching servers');
        setLoading(false);
      });
  };

  const validateFields = () => {
    if (!serverName) {
      setFeedback('Server Name is required');
      return false;
    }
if (game === 'teamspeak' && !rconPort) {
  setFeedback('RCON Port is required for TeamSpeak');
  return false;
}
    if (!port) {
      setFeedback('Port is required');
      return false;
    }
    if (game !== 'valheim' && !rconPort && game !== 'csgo') {
      setFeedback('RCON Port is required');
      return false;
    }
    if (game === 'valheim') {
      if (publicServer && !password) {
        setFeedback('Cannot start a public Valheim server without a password.');
        return false;
      }
      if (password.length < 5) {
        setFeedback('Password for Valheim server must be at least 5 characters long.');
        return false;
      }
      if (type === 'vanilla' && publicServer) {
        setFeedback('Cannot start a public Vanilla Valheim server.');
        return false;
      }
    }
    if (port === rconPort) {
      setFeedback('Port and RCON port cannot be the same.');
      return false;
    }
    if (servers.some(server => server.name === serverName)) {
      setFeedback('Server name already exists');
      return false;
    }
    if (servers.some(server => server.port === parseInt(port) || server.rcon_port === parseInt(port) || server.port === parseInt(rconPort) || server.rcon_port === parseInt(rconPort))) {
      setFeedback('Port or RCON port is already in use');
      return false;
    }
    return true;
  };

  const addServer = () => {
    if (runningServers >= 10 && role !== 'admin') {
      setFeedback('Maximum number of running servers reached (10). Please stop or delete a server before adding a new one.');
      return;
    }

    if (!validateFields()) {
      return;
    }

    setActionLoading(true);
    setFeedback('Adding server...');

    axios.post('https://jelly-hosting.com/create-server', {
      serverName, port, version, type, game, ram, eula: eula ? 'TRUE' : 'FALSE', rcon_port: rconPort, rcon_password: rconPassword, world_name: worldName, password, public_server: publicServer, auto_update: autoUpdate, auto_backup: autoBackup
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setFeedback(response.data);
        fetchServers();
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setFeedback(error.response.data);
        } else {
          setFeedback('Error starting server');
        }
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const startServer = (serverName, newVersion) => {
    setActionLoading(true);
    setFeedback('Starting server...');

    axios.post('https://jelly-hosting.com/start-server', { serverName, newVersion }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setFeedback(response.data);
        fetchServers();
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setFeedback(error.response.data);
        } else {
          setFeedback('Error starting server');
        }
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const stopServer = (serverName) => {
    setActionLoading(true);
    setFeedback('Stopping server...');

    axios.post('https://jelly-hosting.com/stop-server', { serverName }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setFeedback(response.data);
        fetchServers();
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setFeedback(error.response.data);
        } else {
          setFeedback('Error stopping server');
        }
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const deleteServer = (serverName) => {
    const userConfirmed = window.confirm(`🚨ACHTUNG: ["${serverName}"] wird FÜR IMMER gelöscht🚨`);

    if (!userConfirmed) {
      return; // If the user clicks "Cancel", we stop the execution of the function.
    }

    setActionLoading(true);
    setFeedback('Deleting server...');

    axios.delete('https://jelly-hosting.com/delete-server', {
      headers: { Authorization: `Bearer ${token}` },
      data: { serverName }
    })
      .then(response => {
        setFeedback(response.data);
        fetchServers();
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setFeedback(error.response.data);
        } else {
          setFeedback('Error deleting server');
        }
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const stopAllServers = () => {
    const userConfirmed = window.confirm(`🚨ACHTUNG: Es werden alle Server angehalten🚨`);

    if (!userConfirmed) {
      return; // If the user clicks "Cancel", we stop the execution of the function.
    }
    setActionLoading(true);
    setFeedback('Stopping all servers...');

    axios.post('https://jelly-hosting.com/stop-all-servers', {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setFeedback(response.data);
        fetchServers();
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setFeedback(error.response.data);
        } else {
          setFeedback('Error stopping all servers');
        }
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const renderMinecraftOptions = () => (
    <>
      <select value={type} onChange={e => setType(e.target.value)}>
        <option value="vanilla">Vanilla</option>
        <option value="bukkit">Bukkit</option>
        <option value="forge">Forge</option>
      </select>
      <select value={version} onChange={e => setVersion(e.target.value)}>
        <option value="latest">Latest</option>
        <option value="1.16.5">1.16.5</option>
        <option value="1.17.1">1.17.1</option>
        <option value="1.18.2">1.18.2</option>
        <option value="1.19.3">1.19.3</option>
        <option value="1.20.4">1.20.4</option>
        <option value="1.21">1.21</option>
      </select>
      <select value={ram} onChange={e => setRam(e.target.value)}>
        <option value="512M">512 MB</option>
        <option value="1G">1 GB</option>
        <option value="2G">2 GB</option>
        <option value="4G">4 GB</option>
      </select>
      <label>
        <input
          type="checkbox"
          checked={eula}
          onChange={e => setEula(e.target.checked)}
        />
        Accept EULA
      </label>
      <input
        type="text"
        placeholder="RCON Port"
        value={rconPort}
        onChange={e => setRconPort(e.target.value)}
      />
      <input
        type="text"
        placeholder="RCON Password"
        value={rconPassword}
        onChange={e => setRconPassword(e.target.value)}
      />
    </>
  );

  const renderValheimOptions = () => (
    <>
      <input
        type="text"
        placeholder="World Name"
        value={worldName}
        onChange={e => setWorldName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Server Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <label>
        <input
          type="checkbox"
          checked={publicServer}
          onChange={e => setPublicServer(e.target.checked)}
        />
        Public Server
      </label>
      <label>
        <input
          type="checkbox"
          checked={autoUpdate}
          onChange={e => setAutoUpdate(e.target.checked)}
        />
        Auto Update
      </label>
      <label>
        <input
          type="checkbox"
          checked={autoBackup}
          onChange={e => setAutoBackup(e.target.checked)}
        />
        Auto Backup
      </label>
    </>
  );

  const renderGameOptions = () => {
  switch (game) {
    case "":
      return "Please select a Game";
    case 'minecraft':
      return renderMinecraftOptions();
    case 'valheim':
      return renderValheimOptions();
    case 'csgo':
      return <p>CS:GO does not require additional options.</p>;
    case 'ark':
      return (
        <>
          <input
            type="text"
            placeholder="RCON Port (optional)"
            value={rconPort}
            onChange={e => setRconPort(e.target.value)}
          />
          <input
            type="text"
            placeholder="RCON Password (optional)"
            value={rconPassword}
            onChange={e => setRconPassword(e.target.value)}
          />
        </>
      );
    case 'rust':
      return (
        <>
          <input
            type="text"
            placeholder="RCON Port"
            value={rconPort}
            onChange={e => setRconPort(e.target.value)}
          />
          <input
            type="text"
            placeholder="RCON Password"
            value={rconPassword}
            onChange={e => setRconPassword(e.target.value)}
          />
          <select value={ram} onChange={e => setRam(e.target.value)}>
            <option value="512M">512 MB</option>
            <option value="1G">1 GB</option>
            <option value="2G">2 GB</option>
            <option value="4G">4 GB</option>
            <option value="6G">6 GB</option>
            <option value="10G">10 GB</option>
          </select>
        </>
      );
    case 'teamspeak':
      return (
        <>
          <input
            type="text"
            placeholder="RCON Port"
            value={rconPort}
            onChange={e => setRconPort(e.target.value)}
          />
        </>
      );
    default:
      return null;
  }
};


  const renderServerInfo = (server) => {
    const serverInfo = [
      `IP: ${server.serverIp}`,
      `Port: ${server.port}`,
      server.version && `Version: ${server.version}`,
      `Game: ${server.game}`,
      server.type && `Type: ${server.type}`,
      server.ram && `RAM: ${server.ram}`,
      server.rcon_port && `RCON Port: ${server.rcon_port}`,
      server.rcon_password && `RCON Password: ${server.rcon_password}`,
      server.world_name && `World: ${server.world_name}`,
      server.password && `Password: ${server.password}`,
      `Public: ${server.public_server ? 'Yes' : 'No'}`,
      `Auto Update: ${server.auto_update ? 'Enabled' : 'Disabled'}`,
      `Auto Backup: ${server.auto_backup ? 'Enabled' : 'Disabled'}`,
      `Status: ${server.status}`
    ].filter(Boolean).join(', ');

    return serverInfo;
  };

  return (
    <div className="container">
      <p>Running Servers: {runningServers} / 10</p>
      <p>Used RAM Server 1: {usedRamServer1} GB</p>
      <p>Used RAM Server 2: {usedRamServer2} GB</p>

      <div className="subcontainer">
        <div>
          <label htmlFor="Game:">Game</label>
          <select id="game" value={game} onChange={e => setGame(e.target.value)}>
            <option value="">Select Game</option>
            <option value="minecraft">Minecraft</option>
            <option value="valheim">Valheim</option>
            <option value="teamspeak">TeamSpeak</option>
            <option value="ark">ARK</option>
            <option value="rust">Rust</option>
          </select>
        </div>

        <div>
          <label>Server Name:</label>
          <input
            type="text"
            placeholder=""
            value={serverName}
            onChange={e => setServerName(e.target.value)}
          />
        </div>
        <div>
          <label>Port:</label>
          <input
            type="text"
            placeholder="Port"
            value={port}
            onChange={e => setPort(e.target.value)}
          />
        </div>
      </div>

      <div className="subcontainer">
        {renderGameOptions()}
      </div>

      <div className="buttons">
        <button onClick={addServer} disabled={runningServers >= 3}>Add Server</button>
        <button onClick={stopAllServers}>Stop All Servers</button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {servers.map((server, index) => (
            <li key={index}>
              {server.name} ({renderServerInfo(server)})
              <div className="buttons">
                <select
                  value={serverVersions[server.name] || server.version || 'latest'}
                  onChange={(e) =>
                    setServerVersions((prev) => ({ ...prev, [server.name]: e.target.value }))
                  }
                >
                  <option value="latest">Latest</option>
                  <option value="1.16.5">1.16.5</option>
                  <option value="1.17.1">1.17.1</option>
                  <option value="1.18.2">1.18.2</option>
                  <option value="1.19.3">1.19.3</option>
                  <option value="1.20.4">1.20.4</option>
                  <option value="1.21">1.21</option>
                </select>
                <button
                  onClick={() => startServer(server.name, serverVersions[server.name] || server.version || 'latest')}
                  disabled={server.status === 'running' || actionLoading}
                >
                  Start
                </button>
                <button onClick={() => stopServer(server.name)} disabled={server.status === 'stopped' || actionLoading}>Stop</button>
                <button onClick={() => deleteServer(server.name)} disabled={actionLoading}>Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}
      {actionLoading && <p>Loading...</p>}
      <p>{feedback}</p>
    </div>
  );
};

export default ServerManager;
