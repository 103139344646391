import React, { useState } from 'react';
import './Navbar.css';
import jellyLogo from './img/jelly.png';
import logoutIcon from './img/logout_icon.png'; // Pfad zum Logout-Icon

const Navbar = ({ onSelect, selected, role, setToken }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    // Token aus localStorage entfernen
    localStorage.removeItem('usertoken');
    // Token im Zustand zurücksetzen
    setToken('');
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <img className="logo" src={jellyLogo} alt="Logo" />
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`menu ${isMenuOpen ? 'active' : ''}`}>
        {role === 'admin' && (
          <>
            <button className={selected === 'ServerManager' ? 'active' : ''} onClick={() => onSelect('ServerManager')}>Server Manager</button>
            <button className={selected === 'AdminConsole' ? 'active' : ''} onClick={() => onSelect('AdminConsole')}>Admin Console</button>
            <button className={selected === 'DockerLogs' ? 'active' : ''} onClick={() => onSelect('DockerLogs')}>Docker Logs</button>
            <button className={selected === 'StatsChart' ? 'active' : ''} onClick={() => onSelect('StatsChart')}>Stats Chart</button>
            <button className={selected === 'Register' ? 'active' : ''} onClick={() => onSelect('Register')}>Register</button>
            <button className={selected === 'UserAdmin' ? 'active' : ''} onClick={() => onSelect('UserAdmin')}>User Admin</button>
            <button className={selected === 'ServerModManager' ? 'active' : ''} onClick={() => onSelect('ServerModManager')}>Server Mod Manager</button> {/* Neue Option */}
          </>
        )}
        {role !== 'admin' && (
          <button className={selected === 'UserServerManager' ? 'active' : ''} onClick={() => onSelect('UserServerManager')}>User Server Manager</button>
        )}
      </div>

      <div class = "arsch">
        <button class = "logout_btn" onClick={handleLogout}>
          <img src={logoutIcon} alt="Logout" style={{ width: '4em', height: '4em' }} />
        </button>
      </div>

      


    </nav>
  );
};

export default Navbar;
