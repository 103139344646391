import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';

const ServerModManager = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [serverType, setServerType] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modFile, setModFile] = useState(null);
  const [worldFile, setWorldFile] = useState(null);

  useEffect(() => {
    fetchServers();
  }, []);

  const fetchServers = async () => {
    try {
      const res = await axios.get('https://jelly-hosting.com/servers', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const minecraftServers = res.data.servers.filter(server => server.game === 'minecraft');
      setServers(minecraftServers);
      if (minecraftServers.length > 0) {
        setSelectedServer(minecraftServers[0].name);
        fetchServerType(minecraftServers[0].name);
      }
    } catch (error) {
      console.error('Error fetching server names:', error);
    }
  };

  const fetchServerType = async (serverName) => {
    try {
      const res = await axios.get(`https://jelly-hosting.com/server-type?serverName=${serverName}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('Server type:', res.data.type); // Debug log
      setServerType(res.data.type);
    } catch (error) {
      console.error('Error fetching server type:', error);
    }
  };

  const handleServerChange = (e) => {
    const serverName = e.target.value;
    setSelectedServer(serverName);
    fetchServerType(serverName);
  };

  const handleModFileChange = (e) => {
    setModFile(e.target.files[0]);
  };

  const handleWorldFileChange = (e) => {
    setWorldFile(e.target.files[0]);
  };

  const handleModSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('serverName', selectedServer);
    formData.append('mod', modFile);

    try {
      const res = await axios.post('https://jelly-hosting.com/add-mod', formData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setResponse(res.data);
    } catch (error) {
      console.error('Error adding mod:', error);
      if (error.response && error.response.data.includes('Mods can only be added to Bukkit, Spigot, or Paper servers')) {
        setResponse('Mods can only be added to Bukkit, Spigot, or Paper servers');
      } else if (error.response && error.response.data.includes('Container') && error.response.data.includes('state could not be determined')) {
        setResponse('Server not running');
      } else {
        setResponse('Error adding mod');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorldSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('serverName', selectedServer);
    formData.append('world', worldFile);

    try {
      const res = await axios.post('https://jelly-hosting.com/change-world', formData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setResponse(res.data);
    } catch (error) {
      console.error('Error changing world:', error);
      if (error.response && error.response.data.includes('World change is only allowed for vanilla servers')) {
        setResponse('World change is only allowed for vanilla servers');
      } else if (error.response && error.response.data.includes('Container') && error.response.data.includes('state could not be determined')) {
        setResponse('Server not running');
      } else if (error.response && error.response.data.includes("sed: can't read /data/server.properties: No such file or directory")) {
        setResponse('Please wait. The server is starting.');
      } else {
        setResponse('Error changing world');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorldDownload = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`https://jelly-hosting.com/download-world`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { serverName: selectedServer }, // Pass the serverName as a query parameter
        responseType: 'blob' // Important to handle binary data
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedServer}-world.tar.gz`); // Ensure the correct extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading world:', error);
      setResponse('Error downloading world');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Server Mod Manager</h2>
      <form onSubmit={handleModSubmit}>
        <select value={selectedServer} onChange={handleServerChange}>
          {servers.map((server, index) => (
            <option key={index} value={server.name}>{server.name}</option>
          ))}
        </select>
        {serverType && serverType !== 'vanilla' && (
          <>
            <input
              type="file"
              name="mod"
              accept=".jar"
              onChange={handleModFileChange}
            />
            <button type="submit" disabled={isLoading || !modFile}>Add Mod</button>
          </>
        )}
      </form>
      <form onSubmit={handleWorldSubmit}>
        <select value={selectedServer} onChange={handleServerChange}>
          {servers
            .filter(server => ['vanilla', 'bukkit'].includes(server.type))
            .map((server, index) => (
              <option key={index} value={server.name}>{server.name}</option>
            ))}
        </select>
        {serverType && (serverType === 'vanilla' || serverType === 'bukkit') && (
          <>
            <input
              type="file"
              name="world"
              accept=".zip"
              onChange={handleWorldFileChange}
            />
            <button type="submit" disabled={isLoading || !worldFile}>Change World</button>
            <button type="button" onClick={handleWorldDownload} disabled={isLoading}>Download Current World</button>
          </>
        )}
      </form>
      {isLoading ? <div className="loading">Loading...</div> : <pre>{response}</pre>}
    </div>
  );
};

export default ServerModManager;
