import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hasPaid, setHasPaid] = useState(false);
  const [bookedServer, setBookedServer] = useState('none');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://jelly-hosting.com/register', {
        username,
        password,
        hasPaid,
        bookedServers: bookedServer !== 'none' ? [bookedServer] : [] // Ensure bookedServers is an array
      });

      setSuccess(response.data);
      setError(null); // Clear any previous errors
    } catch (err) {
      setError(err.response ? err.response.data : 'Server error');
      setSuccess(null); // Clear any previous success message
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label>
          <input
            type="checkbox"
            checked={hasPaid}
            onChange={(e) => setHasPaid(e.target.checked)}
          /> Has Paid
        </label>
        <select
          value={bookedServer}
          onChange={(e) => setBookedServer(e.target.value)}
          required
        >
          <option value="none">None</option>
          <option value="minecraft">Minecraft</option>
          <option value="rust">Rust</option>
          <option value="valheim">Valheim</option>
        </select>
        <button type="submit">Register</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default Register;
