import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import './styles.css';

Chart.register(...registerables);

const StatsChart = ({ token }) => {
    const [serverStats, setServerStats] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        const fetchServerStats = async () => {
            try {
                const response = await axios.get('https://jelly-hosting.com/api/server-stats', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setServerStats(response.data);

                // Aktualisiere Labels mit Zeitstempel
                const now = new Date().toLocaleTimeString();
                setLabels((prevLabels) => [...prevLabels, now].slice(-20));
            } catch (error) {
                console.error('Error fetching server stats:', error);
            }
        };

        fetchServerStats();
        const interval = setInterval(fetchServerStats, 10000); // Aktualisieren alle 10 Sekunden

        return () => clearInterval(interval);
    }, [token]);

    const chartOptions = {
        scales: {
            x: {
                type: 'category',
                labels: labels,
                ticks: {
                    color: '#555'
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#555'
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: '#555'
                }
            }
        }
    };

    return (
        <div className="wrapper">
            <h2 className="chart-title">Server Stats</h2>
            <div className="chart-grid">
                {serverStats.map((server, index) => (
                    <div key={index} className="chart-item">
                        <h3>{server.serverIp} - CPU Usage</h3>
                        <Line
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: `CPU Usage (%) - ${server.serverIp}`,
                                        data: Array(labels.length).fill(server.cpuUsage),
                                        borderColor: 'rgba(255, 99, 132, 1)',
                                        borderWidth: 1,
                                        fill: false
                                    }
                                ]
                            }}
                            options={chartOptions}
                        />
                    </div>
                ))}
            </div>

            <div className="chart-grid">
                {serverStats.map((server, index) => (
                    <div key={index} className="chart-item">
                        <h3>{server.serverIp} - RAM Usage</h3>
                        <Line
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: `RAM Usage (MB) - ${server.serverIp}`,
                                        data: Array(labels.length).fill(server.memoryUsage),
                                        borderColor: 'rgba(54, 162, 235, 1)',
                                        borderWidth: 1,
                                        fill: false
                                    }
                                ]
                            }}
                            options={chartOptions}
                        />
                    </div>
                ))}
            </div>

            <div className="chart-grid">
                <div className="chart-item">
                    <h3>Disk Usage</h3>
                    <ul>
                        {serverStats.map((server, index) => (
                            <li key={index}>
                                <h4>{server.serverIp}</h4>
                                <ul>
                                    {server.diskUsage.map((disk, i) => (
                                        <li key={i}>
                                            {disk.filesystem}: {disk.used} used / {disk.size} total ({disk.avail} available)
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default StatsChart;
