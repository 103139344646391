import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Import der neuen CSS-Datei

const ServerStatus = ({ token }) => {
  const [status, setStatus] = useState({ cpu: 0, ram: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStatus = async () => {
    setLoading(true);
    try {
      const response = await axios.get('http://vps52165.alfahosting-vps.de:4000/server/status', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStatus({
        cpu: response.data.cpu,
        ram: (response.data.ram / (1024 * 1024)).toFixed(2) // Umrechnung von Bytes in Megabyte
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="server-status-container">
      <h2 className="server-status">Server Status</h2>
      <div className="server-status">
        <strong>CPU Auslastung:</strong> {status.cpu}%
      </div>
      <div className="server-status">
        <strong>RAM Auslastung:</strong> {status.ram} MB
      </div>
      <button onClick={fetchStatus} className="reload-button">Reload</button>
    </div>
  );
};

export default ServerStatus;
