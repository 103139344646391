import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Importiere die CSS-Datei

const options = [
  { value: 'server1', label: 'Server 1' },
  { value: 'server2', label: 'Server 2' },
  { value: 'server3', label: 'Server 3' },
];

const AdminConsole = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [command, setCommand] = useState('');
  const [response, setResponse] = useState('');
  const [gameType, setGameType] = useState('');

  useEffect(() => {
    fetchServers();
  }, []);

  const fetchServers = () => {
    axios.get('https://jelly-hosting.com/servers', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setServers(response.data.servers);
        if (response.data.servers.length > 0) {
          setSelectedServer(response.data.servers[0].name);
          setGameType(response.data.servers[0].game); // Setze den Spieltyp des ersten Servers
        }
      })
      .catch(error => {
        console.error(error);
        alert('Error fetching servers');
      });
  };

  const sendCommand = () => {
    if (!selectedServer) {
      alert('Please select a server');
      return;
    }

    const endpoint = gameType === 'rust' ? 'rcon-command-rust' : 'rcon-command';

    axios.post(`https://jelly-hosting.com/${endpoint}`, { serverName: selectedServer, command }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setResponse(response.data);
      })
      .catch(error => {
        console.error(error);
        alert('Error sending command');
      });
  };

  return (
    <div className="container">
      <h2>Admin Console</h2>

      <div>
        <select class="dropdown" value={selectedServer} onChange={e => {
          setSelectedServer(e.target.value);
          const server = servers.find(server => server.name === e.target.value);
          setGameType(server ? server.game : '');
        }}>
          {servers.map(server => (
            <option key={server.name} value={server.name}>
              {server.name}
            </option>
          ))}
        </select>
      </div>
      
      <div class ="subcontainer">
        <input
          type="text"
          placeholder="Enter command"
          value={command}
          onChange={e => setCommand(e.target.value)}
        />
      </div>
      <div><button onClick={sendCommand}>Send Command</button></div>

      <pre>{response}</pre>
    </div>
  );
};

export default AdminConsole;
