import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';

const UserAdmin = ({ token, setToken }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [maxServers, setMaxServers] = useState(0);
  const [bookedServers, setBookedServers] = useState([]);
  const [serverSettings, setServerSettings] = useState({});
  const [newPassword, setNewPassword] = useState(''); // New state for password

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://jelly-hosting.com/users', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setMaxServers(user.maxServers);
    const parsedBookedServers = JSON.parse(user.bookedServers);
    setBookedServers(Array.isArray(parsedBookedServers) ? parsedBookedServers : []);
    fetchServerSettings(user.id);
    setNewPassword(''); // Reset the password field
  };

  const fetchServerSettings = async (userId) => {
    try {
      const response = await axios.get(`https://jelly-hosting.com/user-servers/${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setServerSettings(response.data);
    } catch (error) {
      console.error('Error fetching server settings:', error);
    }
  };

  const handleAddServer = () => {
    setBookedServers([...bookedServers, '']);
  };

  const handleRemoveServer = (index) => {
    const newBookedServers = bookedServers.filter((_, i) => i !== index);
    setBookedServers(newBookedServers);
  };

  const handleServerChange = (index, value) => {
    const newBookedServers = [...bookedServers];
    newBookedServers[index] = value;
    setBookedServers(newBookedServers);
  };

  const handleServerSettingChange = (serverId, key, value) => {
    setServerSettings({
      ...serverSettings,
      [serverId]: {
        ...serverSettings[serverId],
        [key]: value,
      },
    });
  };

const handleUpdate = async () => {
  try {
    const response = await axios.put('https://jelly-hosting.com/update-user', {
      userId: selectedUser.id,
      maxServers,
      bookedServers,
      serverSettings,
      ...(newPassword && { newPassword }) // Include newPassword only if it's not empty
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    if (newPassword) {
      // Wenn das Passwort geändert wurde, logge den Benutzer aus und leite ihn zur Login-Seite
      alert('Password updated successfully. Please log in again.');
      setToken(null); // Setze den Token zurück
      window.location.href = '/login'; // Leite den Benutzer zur Login-Seite weiter
    } else {
      // Wenn das Passwort nicht geändert wurde, aktualisiere nur die Benutzerdaten
      fetchUsers();
      setSelectedUser(null);
      setMaxServers(0);
      setBookedServers([]);
      setServerSettings({});
      setNewPassword(''); // Reset password after update
    }
  } catch (error) {
    console.error('Error updating user:', error);
  }
};


  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://jelly-hosting.com/delete-user`, {
        data: { userId },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      fetchUsers();
      setSelectedUser(null);
      setMaxServers(0);
      setBookedServers([]);
      setServerSettings({});
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className="container">
      <h1>User Administration</h1>
      <div className="user-list">
        <h2>User List</h2>
        <ul>
          {users.map(user => (
            <li key={user.id} onClick={() => handleUserSelect(user)}>
              {user.username}
              <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
      {selectedUser ? (
        <div className="user-details">
          <h2>User Details</h2>
          <p>Username: {selectedUser.username}</p>
          <label>
            Max Servers:
            <input type="number" value={maxServers} onChange={(e) => setMaxServers(parseInt(e.target.value, 10))} />
          </label>
          <label>
            Booked Servers:
            {bookedServers.map((server, index) => (
              <div key={index} className="server-item">
                <input
                  type="text"
                  value={server}
                  onChange={(e) => handleServerChange(index, e.target.value)}
                  placeholder={`Server ${index + 1}`}
                />
                <button onClick={() => handleRemoveServer(index)}>Remove</button>
              </div>
            ))}
            <button onClick={handleAddServer}>Add Server</button>
          </label>
          <label>
            New Password:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
          </label>
          {Object.keys(serverSettings).length > 0 && (
            <div className="server-settings">
              <h3>Server Settings</h3>
              {Object.entries(serverSettings).map(([serverId, settings]) => (
                <div key={serverId}>
                  <h4>Server ID: {serverId}</h4>
                  <label>
                    RAM:
                    <input
                      type="text"
                      value={settings.ram || ''}
                      onChange={(e) => handleServerSettingChange(serverId, 'ram', e.target.value)}
                    />
                  </label>
                  <label>
                    Version:
                    <input
                      type="text"
                      value={settings.version || ''}
                      onChange={(e) => handleServerSettingChange(serverId, 'version', e.target.value)}
                    />
                  </label>
                  <label>
                    Type:
                    <input
                      type="text"
                      value={settings.type || ''}
                      onChange={(e) => handleServerSettingChange(serverId, 'type', e.target.value)}
                    />
                  </label>
                </div>
              ))}
            </div>
          )}
          <button onClick={handleUpdate}>Update User</button>
        </div>
      ) : (
        <p>Select a user to view and edit details</p>
      )}
    </div>
  );
};

export default UserAdmin;
